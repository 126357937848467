import React from 'react';
import Box, { ColumnBox } from '../Box';
import Image from '../Image';
import Text from '../Text';

interface OwnProps {
  bg?: 'very-light-pink' | 'white';
  heading?: string;
}

const SectionWrapper: React.FC<OwnProps> = ({
  children,
  bg = 'white',
  heading,
}) => (
  <ColumnBox
    bg={bg}
    width={1}
    pt={50}
    pb={50}
    pl={3}
    pr={3}
    justifyContent="space-between"
  >
    <Box
      width={1}
      height="auto"
      maxWidth={['100vw', '66vw', '66vw']}
      ml="auto"
      mr="auto"
    >
      {heading && (
        <ColumnBox
          justifyContent="space-between"
          alignItems="center"
          pb={[40, 60, 70]}
        >
          <Text
            fontSize={[4, 6, 6]}
            fontWeight="bold"
            color="dark-grey"
            pb={[12, 24, 24]}
          >
            {heading}
          </Text>
          <Box width={240} height="auto">
            <Image name="dotLine" />
          </Box>
        </ColumnBox>
      )}
      {children}
    </Box>
  </ColumnBox>
);

export default SectionWrapper;
