import React from 'react';
import { Feature, Scene } from '../components/About';
import Contact from '../components/Contact';
import Heading from '../components/Heading';
import Layout from '../components/Layout/Layout';
import SectionWrapper from '../components/SectionWrapper';
import SEO from '../components/Seo';
import route from '../constants/route';
import LayoutContext from '../contexts/LayoutContext';

const AboutPage = () => {
  const { isMobile } = React.useContext(LayoutContext);
  return (
    <Layout isMobile={isMobile}>
      <SEO
        title={route.about.title}
        keywords={route.about.keywords}
        pathname={route.about.path}
      />
      <Heading heading="ABOUT" subtitle="「育児ケアオンライン」とは？" />
      <SectionWrapper heading="サービスの特徴">
        <Feature />
      </SectionWrapper>
      <SectionWrapper heading="ご利用シーン" bg="very-light-pink">
        <Scene />
      </SectionWrapper>
      {/* <SectionWrapper>
        <OutlinedBoxWithBaloon
          baloonLabel="どんなサポートをしてくれるの？"
          pathname={route.service.path}
          heading="SERVICE"
          label="サポート内容・コースご紹介はこちら"
        />
      </SectionWrapper> */}
      <Contact />
    </Layout>
  );
};

export default AboutPage;
