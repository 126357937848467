import React from 'react';
import { ColumnBox } from '../Box';
import Text, { TextWithBorder } from '../Text';

interface OwnProps {
  bg?: 'very-light-pink' | 'white';
  heading: string;
  subtitle: string;
}

const Heading: React.FC<OwnProps> = ({
  bg = 'very-light-pink',
  heading,
  subtitle,
}) => (
  <ColumnBox
    bg={bg}
    width={1}
    height={[120, 180, 202]}
    pl={[10, 60, 240]}
    pr={[10, 60, 240]}
    justifyContent="center"
    alignItems="flex-start"
  >
    <Text
      color="dusty-orange"
      fontSize={[4, 5, 5]}
      fontWeight={900}
      letterSpacing={[0.8, 1, 1]}
      pt={[1, 2, 4]}
      pb={[1, 2, 4]}
      pr={[1, 2, 4]}
      whiteSpace="noWrap"
    >
      {heading}
    </Text>
    <TextWithBorder fontSize={[5, 7, 7]} fontWeight="bold" color="dark-greyy">
      {subtitle}
    </TextWithBorder>
  </ColumnBox>
);

export default Heading;
